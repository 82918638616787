import axios from 'axios'
const server_path = 'https://espaipobleapi.geo-memorial.cat/api/public/'

////////////////// Generic Functions ////////////////////////

export function getData(url) {
  return axios
    .get(server_path + url)
    .then(res => res)
    .catch(error => {
      if (error.response) {
        return error.response
      } else {
        return error
      }
    })
}

export function postData(url, data) {
  return axios
    .post(server_path + url, data)
    .then(res => res)
    .catch(error => {
      if (error.response) {
        return error.response
      } else {
        return error
      }
    })
}

export function getFile(url, data){
  return axios       
    .post(server_path + url, data, { responseType: "blob" })
    .then(response => {
        const blob = new Blob([response.data]);
        const objectUrl = URL.createObjectURL(blob);
        //console.log(objectUrl);
        return objectUrl;
    })
    .catch(error => {
      if (error.response) {
        return error.response
      } else {
        return error
      }
    })   
}