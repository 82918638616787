<template>
  <div  class="events-container" >
    <div id="loading-container-events" class="loading-gif-container">
        <div style="font-size: 0.9rem; color: #555; margin-bottom: 10px;">Carregant...</div>
        <img class="loading-gif" src="@/assets/icons/loading.gif"  alt="Carregant">
    </div>
    <div class="esdeveniments-title">Esdeveniments</div>
    <div class="events-list" v-if="show_events">
      <Event v-for="(event, index) in event_list" v-bind:key="index" :event="event"></Event>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getData, postData, getFile } from '@/services/api'
import Event from '@/components/village/events/EventCard.vue'

export default {
  name: 'VillageEvents',
  components: {
    Event
  },
  data () {
    return {
      event_list: [],
      show_events: false
    }
  },
  computed: { 
    ...mapGetters({
        village: 'village/village',
        village_events: 'village/events'
    }) 
  },
  mounted () {
    if (this.village_events.length == 0) {
      this.getEvents()
    }
    else {
      this.event_list = this.village_events;
      this.show_events = true
      this.hideLoader()
    }
  },
  methods: {
    getEvents: function () {
      getData('events/' + this.village.id)
      .then (response => {
        if(response.data.events.length == 0) {
          console.log("Sin eventos")
          this.show_events = true
        }
        else {
          this.event_list = response.data.events
          // Se obtiene la ruta del back de las imágenes principales de los eventos
          this.getEventsMediaPaths(0)
        }
      })
    },
    getEventsMediaPaths: function (index) {
      getData('filesevents/' + this.event_list[index].id)
      .then (response => {
        this.event_list[index].image_path = response.data.main

        if(index < this.event_list.length -1) {
          this.getEventsMediaPaths(index + 1)
        }
        else {       
          this.getImages(0)
        }
      })
    },
    getImages: function (index) {
      let formData = new FormData()
      formData.append('path', this.event_list[index].image_path)
      getFile ('filesgetfile', formData)
      .then(response => {
          this.event_list[index].image_url = response
          if(index < this.event_list.length -1) {
            this.getImages(index + 1)
          }
          else {
            this.$store.dispatch('village/change_events', this.event_list)
            this.show_events = true
            this.hideLoader()
          }
      })
    },
    // LOADER
    hideLoader: function () {
      document.getElementById("loading-container-events").classList.add("loading-gif-container-hide")
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>
  /* .events-container {
  height: 84vh;
  overflow-y: scroll;
  padding: 5px 5px;
} */
.esdeveniments-title {
  padding: 10px;
  background-color: #564a40cc;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffebde;
}
.events-list{
  padding: 12px;
  overflow: scroll;
}
</style>
