<template>
  <div class="village-home-container">

  </div>
</template>

<script>

export default {
  name: 'VillageLoad',
  data () {
    return {
    }
  },
  mounted () {
    this.$store.dispatch('ui/change_current_interface', 3)
  }
}

</script>

<style lang= "scss" scoped>

</style>
