<template>
  <div class="container-fluid p-0 home-bg">
    <Header></Header>
    <Manual v-if="show_manual"></Manual>
    <VillageHome v-if="current_interface==1"></VillageHome>
    <VillageLoc v-if="current_interface==2"></VillageLoc>
    <VillageMap v-if="current_interface==3"></VillageMap>
    <VillageEvents v-if="current_interface==4"></VillageEvents>
    <EventDetail v-if="current_interface==5"></EventDetail>
    <VillageLoad v-if="current_interface==99"></VillageLoad>
    
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import { getData, postData, getFile } from '@/services/api'
import { mapGetters } from 'vuex'

// Components
import Header from '@/components/village/Header'
import Footer from '@/components/village/Footer'
import VillageHome from '@/components/village/VillageHome.vue'
import VillageLoc from '@/components/village/VillageLoc.vue'
import VillageMap from '@/components/village/VillageMap.vue'
import VillageEvents from '@/components/village/VillageEvents.vue'
import EventDetail from '@/components/village/events/EventDetail.vue'
import VillageLoad from '@/components/village/VillageLoad.vue'
import Manual from '@/components/village/Manual.vue'


export default {
  name: 'Village',
  components: {
    Header,
    Footer,
    VillageHome,
    VillageLoc,
    VillageMap,
    VillageEvents,
    EventDetail,
    VillageLoad,
    Manual
  },
  created () {
  },
  computed: { 
    ...mapGetters({
        //village: 'village/village',
        current_interface: 'ui/current_interface',
        show_manual: 'ui/show_manual',
    }) 
  },
  data () {
    return {
      village_url: '',
    }
  },
  mounted () {
    this.getVillage()
  },
  methods: {
    getVillage: function () {
      this.village_url = window.location.href.split("/").pop();
      getData('villagename/' + this.village_url)
      .then (response => {
        if(response.data.village.length == 0) {
          this.$store.dispatch('village/change_village', {})
          this.$store.dispatch('village/change_main_image_url', null)
          this.$store.dispatch('village/change_flag_image_url', null)
          this.$router.push('/')
        }
        else {
          this.$store.dispatch('village/change_village', response.data.village[0])
          this.getVillageImages(response.data.village[0].id)
        }
      })
      .catch(error => {
        this.$router.push('/')
      })
    },
    getVillageImages: function (villageId) {
      let url = 'filesvillages/' + villageId
      getData(url)
      .then ( response =>{
        if(response.data.image) {
          this.getVillageImagesURL(response.data.image, true)
        }
        if(response.data.flag) {
          this.getVillageImagesURL(response.data.flag, false)
        }
        
      })
    },
    getVillageImagesURL: function (path, isMain) {
      let formData = new FormData()
      formData.append('path', path)
      getFile('filesgetfile', formData)
      .then(response => {
        if(isMain) {
          this.$store.dispatch('village/change_main_image_url', response)
        }
        else {
          this.$store.dispatch('village/change_flag_image_url', response) 
        }
      })
    }
  }
}
</script>
