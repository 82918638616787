<template>
  <div class="container-fluid p-0 home-bg">
    <div class="darkener">
      <div class="container-home">
        
        <img v-if='this.isListadoOpen' class="home-logo" src="@/assets/images/logo_clic.png"  alt="Logo Clic Poble">
        <img v-else class="home-logo" src="@/assets/images/logo_clic.png"  alt="Logo Clic Poble" style="margin-top: 30%;">

        <!-- <div class="home-welcome-text">
          Espai Poble és una app amb un clar enfoc a mòbil que ens
          mostra tot lo mes necessari d'un poble per algu que el visita per primer cop. Així, està enfocada
          al turisme rural.
        </div> -->
        <div v-if='!this.isListadoOpen' style="flex: 1;"></div>
        <div v-if='this.isListadoOpen' style="flex: 1; width: 100%; position: relative;">
          <VillagesList></VillagesList>
        </div>
        <div v-else class="button-standard" style="width: 100%;" @click="showListado">
          Llistat de pobles
        </div>
        <!-- <div class="button-standard" style="width: 100%;" @click="install">
          Instal·lar
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {postData} from '@/services/api'
import VillagesList from '../components/VillagesList.vue'
import { mapGetters } from 'vuex'
export default {
    name: 'Home',
    components: {
      VillagesList
    },
    data () {
        return {
          deferredPrompt: null   
        }
    },
    computed: { 
        ...mapGetters({
            isListadoOpen: 'ui/isListadoOpen'
        }) 
    },
    created () {
      this.$store.dispatch('ui/change_current_interface', 1)
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
      });
    },
    methods: {
      async install() {
        this.deferredPrompt.prompt();
      },
      notavailable: function () {
        alert('not available')
      },
      showListado: function () {
        this.$store.dispatch('ui/change_listado_status', true)
      },
    }
}
</script>
<style lang="scss" scoped>
.container-home {
  width: 100vw;
  height: 100%;
  padding: 50px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-logo {
  width: 70%;
  max-width: 200px;
  height: auto;
  // margin-top: 30%;
  margin-bottom: 20px;
}
.home-welcome-text {
  font-family: $nav-village-font;
  color: $app-color-1;
  font-size: 1.7rem;
  font-weight: 700;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid $app-color-1;
}
.darkener {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(0,0,0,0.3);
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.84) 100%)
}
</style>
