<template>
    <div class='manual_container'>
        <div class='btn cerrar-btn' @click="closeManual($event)">X</div>        
        




            <div class="modal-popup-cont">
                <div class="mp-title">
                    <p>Instruccions bàsiques</p>
                </div>
                <div class="partition-line"></div>
                <div class="mp-i-cont">
                    <div class="mp-i-title">
                        <p>Punts d'interès</p>
                    </div>
                    <div class="mp-i-question">
                        <p class="p-instruction">Com buscar aparcament:</p>
                    </div>
                    <div class="mp-i-response">
                        <p class="p-instruction">Polsar <span><img src="@/assets/icons/espai/icon2.svg" alt="icona mapa" class="icon-map"></span> i polsar el filtre <span><img src="@/assets/icons/map/icon-parking.svg" alt="icona pàrquig" class="icon-parking"></span>.</p>
                    </div>
                    <div class="mp-i-abstract">
                        <p>Polsar el filtre adequat a qualsevol punt d'interès</p>
                    </div>
                </div>
                <div class="partition-line"></div>			
                <div class="mp-i-cont">
                    <div class="mp-i-title">
                        <p>Esdeveniments</p>
                    </div>
                    <div class="mp-i-question">
                        <p class="p-instruction">Que fan aquest cap de setmana:</p>
                    </div>
                    <div class="mp-i-response">
                        <p class="p-instruction">Polsar <span><img src="@/assets/icons/espai/icon3.svg" alt="icona esdeveniments" class="icon-event"></span> i seleccionar esdeveniment.</p>
                    </div>
                    <div class="mp-i-abstract">
                        <p>Tots els esdeveniments tenen data.</p>
                    </div>
                </div>
                <div class="partition-line"></div>			
                <div class="mp-i-cont">
                    <div class="mp-i-title">
                        <p>Situació</p>
                    </div>
                    <div class="mp-i-question">
                        <p class="p-instruction">On estic?:</p>
                    </div>
                    <div class="mp-i-response">
                        <p class="p-instruction">Polsar <span><img src="@/assets/icons/espai/icon1.svg" alt="icona localització" class="icon-loc"></span>. El mapa es centrarà i marcarà la meva posició.</p>
                        <p class="p-instruction p-ir2">Els filtres de punts d'interès segueixen al mapa.</p>
                        <p class="p-instruction">Tornant a polsar <span><img src="@/assets/icons/espai/icon1.svg" alt="icona localització" class="icon-loc"></span> desapareix del mapa la nostra posició.</p>
                    </div>
                    <div class="mp-i-abstract">
                        <p>Amb la meva situació i el filtre adequat podem arribar a qualsevol punt d'interès.</p>
                    </div>
                </div>
                <div class="partition-line"></div>			
            </div>










    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Manual',
    data () {
        return {

        }
    },
    mounted () {

    },
    methods: {
        closeManual: function (event) {
            this.$store.dispatch('ui/change_show_manual', false)
            event.stopPropagation()
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
    .manual_container {
    width: 100%;
    height: 100%;
    position: relative;
    align-items: center;
    background-color: $app-color-3;
    box-shadow: 0 3px 5px 1px rgb(0 0 0 / 20%);
    z-index: 99999;
    padding: 25px 5px;
    }
    .cerrar-btn {
        position: absolute;
        z-index: 99999;
        top: 0;
        right: 0;
    }
    
    .modal-popup-cont {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        /* max-width: 600px; */
        /* min-width: 300px; */
        width: 100%;
        height: 80%;
        /* margin: 1%; */
        padding: 3%;
        border-radius: 5px;
        color: #2b2b2c;
        overflow-y: auto;
    }
    .partition-line {
        border-top: .15rem solid $app-color-2;
        width: 100%;
        margin-top: .5rem;
    }
    .mp-title {
        font-family: 'Roboto', sans-serif;
        font-size: 1.2rem;
    }
    .mp-i-cont {
        padding: .3rem .5rem;
    }
    .mp-i-title, .mp-i-abstract {
        font-size: .8rem;
        margin: .5rem 0;
    }
    .mp-i-title {
        font-family: 'Roboto', sans-serif;
    }
    .mp-i-question, .mp-i-response {
        font-size: 1rem;
        margin-bottom: .2rem;
    }
    .icon-map, .icon-parking, .icon-event, .icon-loc {
        height: 1.2rem;
    }
    .p-instruction {
        min-height: 1.4rem;
    }
    .p-ir2 {
        margin-top: .3rem;
    }
</style>