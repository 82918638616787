<template>
    <div class="loader-container">
        <div class="loader" v-bind:class="{ 'loading': status==2 }">
        <div class="loader-text" v-bind:class="{ 'spinner-loading': status==2 }">
            Processing...
            <div class="loader-sub-text">This process may take several minutes</div>
        </div>
        <div class="lds-ellipsis" v-bind:class="{ 'spinner-loading': status==2 }"><div></div><div></div><div></div><div></div></div>
        </div>
        <div class="loader-wall" v-bind:class="{ 'loader-wall-loading': status==2 }"></div>
    </div>
</template>
<script>
import { mapGetters } from "vuex"; 
export default {
    name: 'loader',
    computed: {
    ...mapGetters({
      status: 'ui/status',
    }),
  },
}
</script>