<template>
  <footer>
    <div class="footer-content">
      <!-- <div @click="goTo(1)" class="nav-button">
        <img src="@/assets/icons/espai/icon0.svg"  alt="Botó inici">
      </div>  -->
      <div v-if="loadingLoc" class="nav-button">
        <img src="@/assets/icons/loading.gif"  alt="Loc">
      </div>
      <div v-else @click="fireLocateTrigger" class="nav-button">
        <img src="@/assets/icons/espai/icon1.svg"  alt="Loc" v-bind:style="[current_interface != 3 ? {opacity: 0.25} : {opacity: 1}]">
      </div>
      <div @click="goTo(3)" class="nav-button">
        <img src="@/assets/icons/espai/icon2.svg"  alt="Botó mapa">
      </div>
      <div @click="goTo(4)" class="nav-button">
        <img src="@/assets/icons/espai/icon3.svg"  alt="Botó esdeveniments">
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: 'Footer',
  data () {
    return {
    }
  },
  computed: { 
    ...mapGetters({
      current_interface: 'ui/current_interface',
      loadingLoc: 'ui/loadingLoc',
    }) 
  },
  methods: {
    goTo: function (location) {
      if(this.current_interface == 3 && location == 3) {
        location = 99 // 99 takes to map reload
      }
      this.$store.dispatch('ui/change_current_interface', location)
      this.closeManual()  
    },
    fireLocateTrigger: function () {
      this.closeManual()
      this.$store.dispatch('village/change_locate_trigger')
    },
    closeManual: function () {
        this.$store.dispatch('ui/change_show_manual', false)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>
  footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $app-color-2;
    color: $app-color-1;
    padding: 0.2rem 0.2rem 0.2rem 0.2rem;
    /* margin-top: 20px; */
    box-shadow: 0px -2px 3px 2px rgba(0,0,0,0.2);
    z-index: 99999;
  }

  .footer-content {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    img { 
      height: 30px;
    }
  }

  .nav-button {
    height: 40px;
    /* width: 100px; */
    width: 31%;
    background-color: #EDE2D9;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 5px 10px -1px rgba(0,0,0,0.3);
    border-radius: 5px;
  }
</style>
