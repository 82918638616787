<template>
  <div class="village-home-container">
    <div class="village-image">
        <div id="loading-container-home-image" class="loading-gif-container">
          <div style="font-size: 0.9rem; color: #555; margin-bottom: 10px;">Carregant...</div>
          <img class="loading-gif" src="@/assets/icons/loading.gif"  alt="Carregant">
        </div>
        <img v-if="main_image_url" :src="main_image_url"  alt="Imatge de poble">
        <img v-else src="@/assets/images/no-image.png"  alt="Imatge de poble">
    </div>
    <div class="separator">
        <img src="@/assets/icons/espai/text-separator1.png"  alt="Separador de text">
    </div>
    <div class="desc-container">
        <p>{{village.desc}}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'VillageHome',
  data () {
    return {
    }
  },
  computed: { 
    ...mapGetters({
        village: 'village/village',
        main_image_url: 'village/main_image_url',
    }) 
  },
  watch: {
    main_image_url: function () {
      this.hideLoader()
    },
  },
  mounted () {
    if (this.main_image_url) {
      this.hideLoader()
    }   
  },
  methods: {
  // LOADER
    hideLoader: function () {
      document.getElementById("loading-container-home-image").classList.add("loading-gif-container-hide")
    },
  }
}

</script>

<style lang= "scss" scoped>
img {
  width: 100%;
}
.village-image {
  position: relative;
  width: 87%;
  margin-top: 20px;
}
.separator {
  width: 50%;
  opacity: 0.6;
  margin: 10px auto;
}
.desc-container {
  overflow-y: scroll;
}
p { 
  width: 80%;
  margin: 0 auto;
  padding-bottom: 50px;
}
.loading-gif-container {
  background-color: #ead4b7;
}
</style>
