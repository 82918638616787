<template>
  <div id="app">
    <loader></loader>
    <!-- <Header></Header> -->
      <router-view></router-view>
    <!-- <Footer></Footer> -->
  </div>
</template>


<script>
// import Header from '@/components/Header'
// import Footer from '@/components/Footer'
import loader from '@/components/loader'


export default {
  name: 'App',
  components: {
    // Header,
    // Footer,
    loader,
    // confirmModal,
    // notificationModal
  },
   data() {
    return {

    }
  },
  computed: { 
  },
  mounted () {

  },
  methods: { 
  }
}
</script>

<style lang="scss">
#app {
  width:100%; 
  height:100%; 
  position:fixed; 
  left:0;
  top:0;
  overflow:hidden;
}

</style>
