<template>
  <!-- <div class="location-container-inside" @click="closeLocation" @touchstart="fadeOverlay" @touchend="checkSlide"> -->
  <div class="location-container-inside">
    <!-- <div id="loading-container" class="loading-gif-container" v-if="slide.length == 0"> -->
    <div id="loading-container" class="loading-gif-container">
      <div style="font-size: 0.9rem; color: #555; margin-bottom: 10px;">Carregant...</div>
      <img class="loading-gif" src="@/assets/icons/loading.gif"  alt="Carregant">
    </div>
    <div class="arrows-overlay">
      <img v-if="currentSlideIndex > 0" class="filter-arrow" src="@/assets/icons/icon_arrow_left_2.svg" style="height: 30px; pointer-events: all;" @click="navigateSlides('prev')" />
      <div style="flex: 1;"></div>
      <img v-if="currentSlideIndex < slide.length - 2" class="filter-arrow" src="@/assets/icons/icon_arrow_right_2.svg" style="height: 30px; pointer-events: all;" @click ="navigateSlides('next')" />
    </div>

    <swiper :options="swiperOption" ref="mySwiper" class="swiper" style="pointer-events: all; width: 90%;" @slideChange="onSlideChange">
      <swiper-slide v-for="(image, index) in slide" v-bind:key="index"
        class="slide-style"
        :style="{
            backgroundImage: 'url(' + image + ')',
        }"
        @click.native="loadLocation(index)"
        style="pointer-events: all;"
      >
      <div class="location-title">
        {{ slides_names[index] }}
      </div>
      </swiper-slide>
    </swiper>


  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getData, postData, getFile } from '@/services/api'

// import Swiper core and required modules
// import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// Import Swiper Vue.js components
//import { Swiper, SwiperSlide } from 'swiper/vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.min.css'

// Import Swiper styles
// import 'swiper/swiper.scss';
// import 'swiper/components/navigation/navigation.scss';
// import 'swiper/components/pagination/pagination.scss';
// import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper modules
// SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default {
  name: 'TypeGallery',
  components: { 
    Swiper,
    SwiperSlide 
  },
  data () {
    return {
      swiperOption: {
        slidesPerView: 2,
        spaceBetween: 10,
        loop: false,
        grabCursor: true,
        // centeredSlides: true,
        // centeredSlidesBounds: true,
        // initialSlide: 0,
      },
      currentSlideIndex: 0,
      slide: [],
      imagesPaths: [],
      show_text: false,
      slides_names: []
    }
  },
  props: {
    locations: []
  },
  computed: { 
    ...mapGetters({
        village: 'village/village',
    }) 
  },
  created () {
    if(this.locations.length == 1) {
      this.swiperOption.slidesPerView = 1
    }
  },
  mounted () {
    this.getImagesPath()
  },
  methods: {
    onSwiper(swiper) {
      // console.log(swiper);
    },
    onSlideChange() {
      // console.log('slide change');
      // console.log(this.$refs.mySwiper.$swiper.realIndex)
      this.currentSlideIndex = this.$refs.mySwiper.$swiper.realIndex
      this.checkSlide()
    },
    navigateSlides: function (direction) {
      if(direction == 'next'){
        this.$refs.mySwiper.$swiper.slideNext()
      }
      else {
        this.$refs.mySwiper.$swiper.slidePrev()
      }
    },

    getImagesPath: function () {
      this.slide = []
      this.imagesPaths = []
      this.slides_names = []
      if(this.locations.length > 0) {
        this.getImagePath(0)
      }
    },
    getImagePath: function (index) {
      getData('fileslocations/' + this.locations[index].id)
      .then (response => {
        if (response.data.main) {
          this.imagesPaths.push(response.data.main)
          this.slides_names.push(this.locations[index].name)
        }
        if(index < this.locations.length -1) {
          this.getImagePath(index + 1)
        }
        else if(this.imagesPaths.length > 0){
          this.getImages(0)
        }
      })
      
    },
    getImages: function (index) {
      let formData = new FormData()
      formData.append('path', this.imagesPaths[index])
      getFile ('filesgetfile', formData)
      .then(response => {
          this.slide.push(response)
          if(index == 0) {
            this.checkSlide()
            this.hideLoader()
          }
          if(index < this.imagesPaths.length -1) {
            this.getImages(index + 1)
          }
      })
    },
    closeLocation: function () {
      this.$parent.show_overlay = false
      let globalThis = this
      setTimeout(function(){ globalThis.$parent.selected_location = null }, 250);   
    },
    checkSlide: function () {
      let globalThis = this
      setTimeout(function(){    
        if(globalThis.currentSlideIndex == 0) {
          globalThis.show_text = true
        }
        else {
          globalThis.show_text = false
        }
      }, 250)
    },
    fadeOverlay: function () {
      this.show_text = false;
    },
    hideLoader: function () {
      document.getElementById("loading-container").classList.add("loading-gif-container-hide")
    },
    loadLocation: function (index) {
      console.log(this.locations[index])
      this.$parent.selected_location = this.locations[index]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>
  .location-container-inside {
    width: 100%;
    height: 100%;
    z-index: -5;
    /* background-color: orange; */
    /* margin: 5px 0px; */

    display: flex;
    flex-direction: row;
  }
  .event-image-container {
    height: 90px;
    width: 90px;
    background-color: pink;
    background-size: cover;
    background-position: center;
  }
  .event-text-container {
    flex: 1;
    height: 90px;
    background-color: blue;
  }
  .location-overlay {
    position: absolute;
    z-index: 10;
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    pointer-events: all;
    left: 8px;
    right: 50px;
    top: 50px;
    bottom: 14px;
    overflow-y: scroll;
    background-color: rgba(243, 227, 214, 0.8);
    padding: 5px 10px;
    border-radius: 6px;
    opacity: 1;

    -webkit-transition: 0.2s ease-out;
    -moz-transition: 0.2s ease-out;
    -o-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
  }
  .location-overlay-hidden {
    opacity: 0;
    pointer-events: none;
  }
  .location-main-title {
    font-weight: 600;
    border-bottom: 1px solid $app-color-1;
    width: 100%;
  }
  .location-text-title {
    font-size: 0.7rem;
    font-weight: 600;
    color: #352211;
  }
  .location-text {
    font-size: 0.7rem;
  }
  .text-line {
    line-height: 0.8;
  }
  .arrows-overlay {
    position: absolute;
    z-index: 20;
    display: flex;
    flex-direction: row;
    align-items: center;
    pointer-events: none;
    left: 8px;
    right: 8px;
    top: 34px;
    bottom: 14px;
    padding: 5px 10px;
  }
  .slide-style {
    background-size: cover;
  }
  .location-title {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e7d5c8;
    padding: 5px;
    font-size: 1rem;
    font-family: tangerine;
    font-weight: bolder;
  }
  .loading-gif-container {
    position: absolute;
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-transition: 0.3s ease-out;
    -moz-transition: 0.3s ease-out;
    -o-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
    opacity: 1;
    z-index: 10000;
    background-color: $app-color-3;
  }
  .loading-gif-container-hide {
    opacity: 0;
    pointer-events: none;
  }
  .loading-gif {
    width: 18%;
    height: auto;
  }
</style>
