<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="row nav-content-espai">
          <div >
            <div  class="navbar-title" @click="goTo(1)">
              <img v-if="flag_image_url" :src="flag_image_url" class="escudo-pueblo"  alt="Bandera de poble">
              <img v-else src="@/assets/images/no-image.png" class="escudo-pueblo"  alt="Bandera de poble">
            </div>
          </div>
          <div @click="goTo(1)">
              <div class="village-title" v-if="village">{{village.name}}</div>
              <div class="village-title" v-else>Poble</div>
          </div>
          <div >
              <a id="navbarDropdown" class="link-1" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <!-- <img :src="require('@/assets/icons/icon-user.svg')" alt="Profile picture" class="profile-picture"/> -->
                <img src="@/assets/icons/espai/hamburguer-menu.svg" class="d-inline-block nav-user m-0 link-1 hamburger">
              </a>

                            <!-- Options pop up -->
              <div class="dropdown-menu dropdown-menu-right dropdown-user" aria-labelledby="navbarDropdown">
                <div class="popup-body">
                  <div class="dropdown-item modal-call pointer"  data-toggle="modal" data-target="#history" @click="showManual">Instruccions</div>
                  <div class="line-separator"><hr /></div>
                  <div class="dropdown-item modal-call pointer" data-toggle="modal" data-target="#management" @click="salir">Sortir</div>
                  <!-- <div class="dropdown-item modal-call pointer"  data-toggle="modal" data-target="#userProfile" >Option 3</div>
                  <div class="line-separator"><hr /></div>
                  <div>
                    <a  @click="test" class="dropdown-item pointer" href="">
                      Option 4
                    </a>
                  </div> -->
                </div>
                  <!-- </div>
                </li>
              </ul> -->
            </div>
          </div>
        </div>

        <!-- Right side of Navbar -->
        <!-- <div class="collapse navbar-collapse" id="navbarToggler">
          <ul class="nav navbar-nav navbar-right ml-auto ">
            <li class="nav-item dropdown mr-5 nav-inicio"> -->
    </nav>
    <!-- <history></history> 
    <management v-if="is_admin == true || is_admin == 'true'"></management> 
    <userProfile v-if="isLoggedIn == true || isLoggedIn == 'true'"></userProfile> -->
  </div>
</template>
<script>

import { mapGetters } from 'vuex'


export default {
  name: "Header",
  components: {

  },
  data() {
    return {
    };
  },
  computed: { 
    ...mapGetters({
      village: 'village/village',
      flag_image_url: 'village/flag_image_url'
    }) 
  },
  methods:{
    goTo: function (location) {
      this.closeManual()
      this.$store.dispatch('ui/change_current_interface', location)
    },
    salir: function () {
      this.closeManual()
      this.$store.dispatch('ui/change_listado_status', false)
      this.$router.push('/')
    },
    showManual: function () {
      this.$store.dispatch('ui/change_show_manual', true)
    },
    closeManual: function () {
        this.$store.dispatch('ui/change_show_manual', false)
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>

.nav-inicio {
    align-content: center;
    display: grid
}

.profile-picture{
    width: 25px;
    padding-right: 5px;
}

.dropdown-user {
  z-index: 10000;
}

.navbar {
  z-index: 100000 !important;
}






</style>
