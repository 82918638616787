<template>
    <div class='villages_list_container'>
        <div class='btn cerrar-btn' @click="closeListado($event)">
            <button type="button" class="btn-close">
                <span class="icon-cross"></span>
                <!-- <span class="visually-hidden">Close</span> -->
            </button>
        </div>        
        <div v-if="this.villages_list.length > 0" class='village_list'>
            <div v-for="(comarca, index) in villages_list" v-bind:key="index">
                <div class="comarca-label">
                    <h6>{{comarca.name}}</h6>
                </div>
                <div v-for="(poble, index_poble) in comarca.pobles" v-bind:key="index_poble">
                    <div class="village-card" @click='goTo(poble.nameurl)'>
                        <h6>{{poble.name}}</h6>
                        <p>{{poble.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getData} from '@/services/api'


export default {
    name: 'VillagesList',
    components: {
        
    },
    data () {
        return {
            villages_list: [],
        }
    },
    computed: { 
        ...mapGetters({
            isListadoOpen: 'ui/isListadoOpen'
        }) 
    },
    mounted () {
        this.getVillages()
    },
    methods: {
        getVillages: function () {
        getData('villages')
        .then (response => {
            if(response.data.villages.length == 0) {
                this.closeListado()
                alert('Encara no hi ha pobles')
            }
            else {
                let villages_list_unordered = response.data.villages
                let comarques = []
                villages_list_unordered.forEach( village => {
                    if (!comarques.includes(village.comarca)) {
                        comarques.push(village.comarca)
                    }
                })

                comarques.forEach(comarca => {
                    let comarca_object = {}
                    comarca_object.name = comarca
                    comarca_object.pobles = []
                    villages_list_unordered.forEach( village => {
                        if (village.comarca == comarca) {
                            comarca_object.pobles.push(village)
                        }
                    })
                    this.villages_list.push(comarca_object)
                })
            }
        })
        }, 
        closeListado: function (event) {
            this.$store.dispatch('ui/change_listado_status', false)
            event.stopPropagation()
        },
        goTo: function (url) {
            this.$router.push('/'+url)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>

    .villages_list_container {
    width: 100%;
    //height: 100%;
    top: 0;
    bottom: 0;
    position: absolute;
    border-radius: 5px;
    align-items: center;
    /* background-color: $app-color-2; */
    background-color: #e6d2b540;
    box-shadow: 0 3px 5px 1px rgb(0 0 0 / 20%);
    z-index: 99999;
    padding: 25px 5px;
    overflow-y: scroll;
    }

    .village-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    align-items: center;
    background-color: $app-color-2;
    box-shadow: 0 3px 5px 1px rgb(0 0 0 / 20%);
    }

    .cerrar-btn {
        position: absolute;
        z-index: 99999;
        top: 0;
        right: 0;
        padding: .375rem !important;
    }

    .comarca-label {
        margin-top: 15px;
        color: #e6d2b5;
    }
    
    // Display a cross with CSS only.
    //
    // $size  : px or em
    // $color : color
    // $thickness : px
    @mixin cross($size: 20px, $color: currentColor, $thickness: 1px) {
        margin: 0;
        padding: 0;
        border: 0;
        background: none;
        position: relative;
        width: $size;
        height: $size;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: ($size - $thickness) / 2;
            left: 0;
            right: 0;
            height: $thickness;
            background: $color;
            border-radius: $thickness;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }

        span {
            display: block;
        }

    }


    // Example 1.
    .btn-close {
        margin: 0;
        border: 0;
        padding: 0;
        background: #e6d2b555;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 150ms;
        
        .icon-cross {
            @include cross(20px, #302b25, 3px);
        }
        &:hover,
        &:focus {
            transform: rotateZ(90deg);
            background: hsl(216, 100, 40);
        }

    }

</style>