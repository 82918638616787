const state = () => ({
  status: 1, // 1.- Normal / 2.- Loading / 3.- Waiting for response / 4.- Error
  current_interface: 1, // 1.- Village home / 2.- Loc / 3.- Map / 4.- Events / 5.- Event Detail
  current_event: null,
  isListadoOpen: false,
  show_manual: false,
  loadingLoc: false
})

const mutations = {
  set_status(state, data){
    state.status = data
  },
  set_current_interface(state, data){
    state.current_interface = data
  },
  set_current_event(state, data){
    state.current_event = data
  },
  set_listado_status(state, data){
    state.isListadoOpen = data
  },
  set_show_manual(state, data){
    state.show_manual = data
  },
  setLoadingLoc(state, data){
    state.loadingLoc = data
  },
}

const actions = {
  change_status({ commit }, status) {
    commit("set_status", status)
  },
  change_current_interface({ commit }, current_interface) {
    commit("set_current_interface", current_interface)
  },
  change_current_event({ commit }, current_event) {
    commit("set_current_event", current_event)
  },
  change_listado_status({ commit }, isListadoOpen) {
    commit("set_listado_status", isListadoOpen)
  },
  change_show_manual({ commit }, showManual) {
    commit("set_show_manual", showManual)
  },
  changeLoadingLoc({ commit }, isLoadingLoc) {
    commit("setLoadingLoc", isLoadingLoc)
  }
}

const getters = {
    status: state => state.status,
    current_interface: state => state.current_interface,
    current_event: state => state.current_event,
    isListadoOpen: state => state.isListadoOpen,
    show_manual: state => state.show_manual,
    loadingLoc: state => state.loadingLoc
  }

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }