<template>
  <div>
    <div class="full-container">
      <div id="map" class="map-container"></div>
      <div class="recenter" @click="centerView"></div>
      <div class="overlay-container" v-bind:class="{ 'hide-overlay': !show_overlay }">
        <div class="overlay-header" @click="toggleOverlay">
          <!-- <div class="arrow-header-container"></div> -->
          <img v-if="show_overlay" class="filter-arrow" src="@/assets/icons/icon_arrow_down_2.svg" />
          <img v-else class="filter-arrow" src="@/assets/icons/icon_arrow_up_2.svg" />
        </div>
        <div v-if="selected_location" class="location-container">
          <Location v-if="selected_location" :location="selected_location"></Location>
        </div>
        <div v-else class="filters-container">
          <!-- <div>Filtres</div> -->
          <div class="filters-column" style="width: 25%;">
            <div class="filters-row" style="height: 90%;">
              <label for="checkbox-0" class="checkbox-container" v-bind:class="{ 'type-selected': filter_check_all }">
                <input type="checkbox" id="checkbox-0" v-model="filter_check_all">
                <img class="icon-filter" src="@/assets/icons/map/icon_all.svg" />
              </label>
            </div>
          </div>
          <div class="filters-column" style="flex: 1;">
            <div class="filters-row">
              <label for="checkbox-1" class="checkbox-container" v-bind:class="{ 'type-selected': filter_checks.salut }">
                <input type="checkbox" id="checkbox-1" v-model="filter_checks.salut">
                <img class="icon-filter" src="@/assets/icons/map/marker_salut.svg" />
              </label>
              <label for="checkbox-2" class="checkbox-container" v-bind:class="{ 'type-selected': filter_checks.menjar }">
                <input type="checkbox" id="checkbox-2" v-model="filter_checks.menjar">
                <img class="icon-filter" src="@/assets/icons/map/marker_menjar.svg" />
              </label>
              <label for="checkbox-3" class="checkbox-container" v-bind:class="{ 'type-selected': filter_checks.aparcament }">
                <input type="checkbox" id="checkbox-3" v-model="filter_checks.aparcament">
                <img class="icon-filter" src="@/assets/icons/map/marker_aparcament.svg" />
              </label>
            </div>
            <div class="filters-row">
              <label for="checkbox-4" class="checkbox-container" v-bind:class="{ 'type-selected': filter_checks.comer }">
                <input type="checkbox" id="checkbox-4" v-model="filter_checks.comer">
                <img class="icon-filter" src="@/assets/icons/map/marker_comer.svg" />
              </label>
              <label for="checkbox-5" class="checkbox-container" v-bind:class="{ 'type-selected': filter_checks.dormir }">
                <input type="checkbox" id="checkbox-5" v-model="filter_checks.dormir">
                <img class="icon-filter" src="@/assets/icons/map/marker_dormir.svg" />
              </label>
              <label for="checkbox-6" class="checkbox-container" v-bind:class="{ 'type-selected': filter_checks.entitats }">
                <input type="checkbox" id="checkbox-6" v-model="filter_checks.entitats" >
                <img class="icon-filter" src="@/assets/icons/map/marker_entitats.svg" />
              </label>
            </div>
            <div class="filters-row">
              <label for="checkbox-7" class="checkbox-container" v-bind:class="{ 'type-selected': filter_checks.especific }">
                <input type="checkbox" id="checkbox-7" v-model="filter_checks.especific">
                <img class="icon-filter" src="@/assets/icons/map/marker_especific.svg" />
              </label>
              <label for="checkbox-8" class="checkbox-container" v-bind:class="{ 'type-selected': filter_checks.PIT }">
                <input type="checkbox" id="checkbox-8" v-model="filter_checks.PIT">
                <img class="icon-filter" src="@/assets/icons/map/marker_PIT.svg" />
              </label>
              <label for="checkbox-9" class="checkbox-container" v-bind:class="{ 'type-selected': filter_checks.espais }">
                <input type="checkbox" id="checkbox-9" v-model="filter_checks.espais">
                <img class="icon-filter" src="@/assets/icons/map/marker_espais.svg" />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Leaflet
import "leaflet/dist/leaflet.css"
import L from "leaflet"
// Vuex
import { mapGetters } from "vuex";
// Services
import { getData } from '@/services/api'
// Components
import Location from '@/components/village/map/Location.vue'

export default {
  name: 'VillageLoc',
  components: {
    Location
  },
  data () {
    return {
      map: null,
      locations: [],
      selected_location: null,
      markers: [],
      show_overlay: false,
      filter: ['0', '1', '2', '3', '4', '5', '6', '7', '8'],
      filter_check_all: true,
      is_reacting: false,
      filter_checks: {
        salut: false,
        menjar: false,
        aparcament: false,
        comer: false,
        dormir: false,
        entitats: false,
        especific: false,
        PIT: false,
        espais: false
      },
      icon_salut: null,
      icon_menjar: null,
      icon_aparcament: null,
      icon_comer: null,
      icon_dormir: null,
      icon_entitats: null,
      icon_especific: null,
      icon_PIT: null,
      icon_espais: null,
      icon_user: null,
      user: null,
      firstLocation: true
    }
  },
  computed: { 
    ...mapGetters({
      village: 'village/village',
    }) 
  },
  watch: {
    filter: function () {
      this.setMarkers()
    },
    filter_check_all: function () {
      this.setFilters(true)
    },
    filter_checks: {
      handler(){
        this.setFilters(false)
      },
      deep: true
    }
  },
  mounted () {
    this.setBaseMap()
    this.getLocations()
    this.loadIcons()
    this.locateUser()
  },
  methods: {
    locateUser: function () {
      // this.map.on('locationfound', this.onLocationFound)
      // this.map.locate({setView: true, watch: true, maxZoom: 16})
      this.map
      this.map.locate({watch: true, maxZoom: 16}).on('locationfound', this.onLocationFound)
    },
    onLocationFound(e){
      //console.log(e)
      //let radius = e.accuracy;
      if (!this.user) {
          this.user = L.marker([e.latitude, e.longitude], { icon: this.icon_user }).addTo(this.map);
      } else {
          this.user.setLatLng([e.latitude, e.longitude]);
      }
      // this.user = L.marker(e.latlng, {icon: this.icon_user})
      // this.user.addTo(this.map)
      //    .bindPopup("You are within " + radius + " meters from this point").openPopup();
      //L.circle(e.latlng, radius).addTo(this.map);
      if (this.firstLocation) {
        this.firstLocation = false
        this.centerView()
      }
    },
    centerView: function () {
      let groupArray = []
      this.markers.forEach(marker => {
        groupArray.push(marker)
      })
      groupArray.push(this.user)
      let group = new L.featureGroup(groupArray)
      this.map.fitBounds(group.getBounds())
    },
    setBaseMap: function () {
      this.map = L.map("map", {zoomControl: false}).setView([this.village.lat, this.village.long], 16);
      L.tileLayer(
        //"https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
        'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
        {
          attribution: '&copy; <a href="https://www.geo-memorial.cat">Geo Memorial</a>',
        }
      ).addTo(this.map)
      L.control.scale({imperial: false}).addTo(this.map)
    },
    getLocations: function () {
      getData('locations/' + this.village.id)
      .then (response => {
        //console.log(response)
        this.locations = response.data.locations
        this.setMarkers()
        //this.$store.dispatch('village/change_locations', response.data.locations)
      })
    },
    loadIcons: function () {
      let image_salut = require('@/assets/icons/map/marker_salut.svg')
      let image_menjar = require('@/assets/icons/map/marker_menjar.svg')
      let image_aparcament = require('@/assets/icons/map/marker_aparcament.svg')
      let image_comer = require('@/assets/icons/map/marker_comer.svg')
      let image_dormir = require('@/assets/icons/map/marker_dormir.svg')
      let image_entitats = require('@/assets/icons/map/marker_entitats.svg')
      let image_especific = require('@/assets/icons/map/marker_especific.svg')
      let image_PIT = require('@/assets/icons/map/marker_PIT.svg')
      let image_espais = require('@/assets/icons/map/marker_espais.svg')
      let image_user = require('@/assets/icons/espai/icon1.svg')
      this.icon_salut = L.icon({
        iconUrl: image_salut,
        iconSize:     [60, 60], // size of the icon
        iconAnchor:   [30, 60], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
      this.icon_menjar = L.icon({
        iconUrl: image_menjar,
        iconSize:     [60, 60], // size of the icon
        iconAnchor:   [30, 60], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
      this.icon_aparcament = L.icon({
        iconUrl: image_aparcament,
        iconSize:     [60, 60], // size of the icon
        iconAnchor:   [30, 60], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
      this.icon_comer = L.icon({
        iconUrl: image_comer,
        iconSize:     [60, 60], // size of the icon
        iconAnchor:   [30, 60], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
      this.icon_dormir = L.icon({
        iconUrl: image_dormir,
        iconSize:     [60, 60], // size of the icon
        iconAnchor:   [30, 60], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
      this.icon_entitats = L.icon({
        iconUrl: image_entitats,
        iconSize:     [60, 60], // size of the icon
        iconAnchor:   [30, 60], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
      this.icon_especific = L.icon({
        iconUrl: image_especific,
        iconSize:     [60, 60], // size of the icon
        iconAnchor:   [30, 60], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
      this.icon_PIT = L.icon({
        iconUrl: image_PIT,
        iconSize:     [60, 60], // size of the icon
        iconAnchor:   [30, 60], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
      this.icon_espais = L.icon({
        iconUrl: image_espais,
        iconSize:     [60, 60], // size of the icon
        iconAnchor:   [30, 60], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
      this.icon_user = L.icon({
        iconUrl: image_user,
        iconSize:     [40, 40], // size of the icon
        iconAnchor:   [20, 20], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
    },
    setMarkers: function () {
      this.markers.forEach(marker => {
        this.map.removeLayer(marker) 
      })
      this.markers = []
      this.locations.forEach(location => {
        let typeArray = this.formatTypeArray(location.type)
        let currentIcon = null
        let isInFilter = false
        let filterType = null
        typeArray.forEach(type => {
          if (this.filter.includes(type)) {
            isInFilter = true
            filterType = type
          }
        })

        if(isInFilter) {
          if (filterType == "0") {
            currentIcon = this.icon_especific
          }
          else if (filterType == "1") {
            currentIcon = this.icon_salut
          }
          else if (filterType == "2") {
            currentIcon = this.icon_entitats
          }
          else if (filterType == "3") {
            currentIcon = this.icon_espais
          }
          else if (filterType == "4") {
            currentIcon = this.icon_menjar
          }
          else if (filterType == "5") {
            currentIcon = this.icon_dormir
          }
          else if (filterType == "6") {
            currentIcon = this.icon_comer
          }
          else if (filterType == "7") {
            currentIcon = this.icon_aparcament
          }
          else if (filterType == "8") {
            currentIcon = this.icon_PIT
          }
          this.markers.push(L.marker([location.lat, location.long], {icon: currentIcon}))
          this.markers[this.markers.length - 1].addTo(this.map).on("click", () => {
            this.selectLocation(location)
          })
        }
      })  
    },
    formatTypeArray(typestring) {
        let typeArray = typestring.split(",");
        for(let i = 0; i < typeArray.length; i++) {
            if(i == 0) {
                typeArray[i] = typeArray[i].substring(1)
            }
            if(i == typeArray.length -1) {
                typeArray[i] = typeArray[i].slice(0, -1)
            }
        }
        return typeArray
    },
    setFilters: function(is_all) {
        if(is_all) { 
          if(this.filter_check_all){
            // Object.entries(this.filter_checks).forEach(([key, value]) => {
            //   this.filter_checks[key] = false
            // });
            this.is_reacting = true
            this.filter_checks = {
              salut: false,
              menjar: false,
              aparcament: false,
              comer: false,
              dormir: false,
              entitats: false,
              especific: false,
              PIT: false,
              espais: false
            }
            this.filter = ['0', '1', '2', '3', '4', '5', '6', '7', '8']
          }
          else {
            if (this.is_reacting) {
              this.is_reacting = false
            }
            else {
              this.filter = []
            }
          }
        }
        else {
          if (this.is_reacting) {
            this.is_reacting = false
          }
          else {
            if (this.filter_check_all) {
              this.is_reacting = true
              this.filter_check_all = false
            }

            this.filter = []
            Object.entries(this.filter_checks).forEach(([key, value]) => {
              if (value == true){
                if (key == 'especific') this.filter.push('0')
                else if (key == 'salut') this.filter.push('1')
                else if (key == 'entitats') this.filter.push('2')
                else if (key == 'espais') this.filter.push('3')
                else if (key == 'menjar') this.filter.push('4')
                else if (key == 'dormir') this.filter.push('5')
                else if (key == 'comer') this.filter.push('6')
                else if (key == 'aparcament') this.filter.push('7')
                else if (key == 'PIT') this.filter.push('8')
              }
            });
          }
        } 
    },
    toggleOverlay: function () {
      if(this.show_overlay){
        let globalThis = this
        setTimeout(function(){ globalThis.selected_location = null }, 250);
      }
      this.show_overlay = !this.show_overlay
    },
    selectLocation: function (location) {
      this.selected_location = null
      //console.log(location)
      this.selected_location = location
      this.show_overlay = true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>
.overlay-container {
  position: absolute;
  width: 100%;
  background-color: $app-color-3;
  z-index: 10000;
  bottom: 0;
  overflow: hidden;
  height: 35%;

  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}
.hide-overlay {
  height: 40px;
}
.icon-filter {
  height: auto;
  height: 40px;
  padding: 3px;
}
.filters-container {
  padding: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 30px);
  margin-top: 5px;
}
.filters-column {
  display: flex;
  flex-direction: column;
}
.filters-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 30%;
}
.overlay-header {
  width: 100%;
  height: 40px;
  background-color: $app-color-2;
  opacity: 1;
  /*border-top: 1px solid $app-color-1;*/
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 3px 2px rgba(0,0,0,0.2);
  border-top: 1px solid #958c85
}
.arrow-header-container {
  background-color: pink;
  height: 30px;
  width: 30px;
  position: absolute;
  top: -15px;
}
.filter-arrow {
  height: 50%;
  width: auto;
  opacity: 0.25;
}
.location-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 30px);
  pointer-events: none;
}
/* CHECKBOXES */
input[type="checkbox"][id^="checkbox-"] {
  display: none;
}
label {
  background-color: #e6ded8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: 1px solid #b2a79e;
  margin: 3px;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 1px rgb(0 0 0 / 10%);

  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}
.type-selected {
  /* border: 1px solid #f5a06d; */
  box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 30%);
  background-color: #f3ece7;
}

/* LOCATION */
.recenter {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 35px;
  height: 35px;
  z-index: 9999;
  opacity: 0.6;
  background-image: url('~@/assets/icons/map/icon_recenter_3.svg');;
}
</style>
