<template>

    <div class="event-container">
        <!-- <button class="btn btn-success" @click="goBack">Atras</button>
        <div>
            <h6 style="padding:10px;">{{this.event.title}}</h6>
            <div id="map" class="map-detail-container"></div>
            <div class="description-detail-container">{{this.event.desc}} Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia quidem a dolorem voluptate dignissimos eos? Saepe necessitatibus, itaque id, officia illum repellat sunt libero voluptas ullam debitis recusandae totam minus!</div>
        </div> -->

        <div class="esdeveniments-title">
            <img src="@/assets/icons/back_arrow.svg" style="width: 14px; margin-left: 5px;" @click="goBack()"/>
            <div style="flex: 1;"></div>
            <div style="margin-right: 19px;">{{event.title}}</div>
            <div style="flex: 1;"></div>
        </div>
        <div class="arrows-overlay">
            <div class="arrow-background-up">    
                <img v-if="currentSlideIndex > 0" class="filter-arrow arrow-up" src="@/assets/icons/icon_arrow_left_2.svg" style="height: 30px; pointer-events: all;" @click="navigateSlides('prev')" />
            </div>
            <div style="flex: 1;"></div>
            <div class="arrow-background-down">  
                <img v-if="currentSlideIndex < slides.length - 1" class="filter-arrow arrow-down" src="@/assets/icons/icon_arrow_right_2.svg" style="height: 30px; pointer-events: all;" @click ="navigateSlides('next')" />
            </div>
        </div>
        <div class="video-overlay" v-show="isPlayingVideo">
            <div class="icon-close" @click="stopvid()"></div>
            <video ref="videoelement" id="myVideo" style="width: 80%; height: 30%" controls>
                <!-- <source :src="slide.multimedia_url" type="video/mp4"> -->
                Your browser does not support HTML5 video.
            </video>
        </div>

        <swiper
            class="mh-50 oh width-100"
            v-if="showSlides"
            style="pointer-events: all; height: 100%;"
            ref="mySwiper"
            :options="swiperOption"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
        >
            <swiper-slide v-for="(slide, index) in slides" v-bind:key="index" class="slide-style">
                <div class="slide-container">
                    <div class="slider-background" :style="{ background: 'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(' + slide.url + ')' }"></div>
                    <div class="slider-foreground">
                        <div v-if="!slide.multimedia_type" class="slide-media" :style="{ background: 'url(' + slide.url + ')' }">
                            <!-- <img v-if="slide.url" :src="slide.url" style="width: 100%;"/> -->
                        </div>
                        <div v-else-if="slide.multimedia_type=='video'" class="slide-media" :style="{ background: 'url(' + slide.url + ')' }" @click="playVideo(slide.multimedia_url)">
                            <div class="video-icon"></div>
                        </div>
                        <div v-else-if="slide.multimedia_type=='audio'" class="slide-media" :style="{ background: 'url(' + slide.url + ')' }" @click="playVideo(slide.multimedia_url)">
                            <div class="audio-icon"></div>
                        </div>
                       
                        <!-- <div v-else-if="slide.multimedia_type=='video'" class="slide-media video-image" @click="playVideo(slide.multimedia_url)"></div> -->                
                        <!-- <div v-else-if="slide.multimedia_type=='audio'" class="slide-media audio-image"></div> -->
                        <div v-if="slide.desc" class="slide-desc">{{ slide.desc }}</div>
                    </div>
                    <!-- <div class="slide-media">
                        <img v-if="slide.url" :src="slide.url" style="width: 100%;"/>
                    </div>
                    <div class="slide-desc">{{slide.desc}}</div> -->
                </div>
            </swiper-slide>
            <!-- <swiper-slide v-for="(slide, index) in slides" v-bind:key="index"
                class="slide-style slide-background"
                :style="{
                    background: 'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(' + slide.url + ')',
                }"
            >
                <div class="slide-container">
                    <div class="slide-media">
                        <img v-if="slide.url" :src="slide.url" style="width: 100%;"/>
                    </div>
                    <div class="slide-desc">{{slide.desc}}</div>
                </div>
            </swiper-slide> -->
            <!-- <swiper-slide v-for="(slide, index) in slides" v-bind:key="index"
                class="slide-style"
                :style="{
                    backgroundImage: 'url(' + slide.url + ')',
                }"
            >
            </swiper-slide> -->
            <!-- <swiper-slide v-for="(slide, index) in slides" v-bind:key="index" style="height: calc(100vh - 56px);">
                <div class="slide-container">
                    <div class="slide-media">
                        <img v-if="slide.url" :src="slide.url" style="width: 100%;"/>
                    </div>
                    <div class="slide-desc">{{slide.desc}}</div>
                </div>
                <span style="font-size: 0.5rem;">.</span>
            </swiper-slide> -->
        </swiper>
    </div>

</template>

<script>
import { getData, getFile } from '@/services/api'

// import Swiper core and required modules
//import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.min.css'

// Import Swiper styles
// import 'swiper/swiper.scss';
// import 'swiper/components/navigation/navigation.scss';
// import 'swiper/components/pagination/pagination.scss';
// import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper modules
// SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

import {mapGetters} from 'vuex'

export default {
    name: 'EventDetail',
    components: { 
        Swiper,
        SwiperSlide 
    },
    data () { 
        return {
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 5,
                loop: false,
                grabCursor: true,
                direction: 'vertical'
                // centeredSlides: true,
                // centeredSlidesBounds: true,
                // initialSlide: 0,
            },
            currentSlideIndex: 0,
            slide: [],
            audio_video: [],
            imagesPaths: [],
            show_text: false,
            slides_names: [],
            slides: [],

            showSlides: false,
            image_types: ["jpg", "JPG", "jpeg", "JPEG", "png", "PNG"],
            media_types: ["avi", "AVI", "mpeg", "MPEG", "mpg", "MPG", "mp4", "MP4"],

            isPlayingVideo: false,
        }
    },
    computed: {
        ...mapGetters({
            event: 'ui/current_event'
        })
    },
    mounted () {
        this.getEventMedia()
        //this.setBaseMap()
    },
    methods: {
        getEventMedia: function () {
            getData('filesevents/' + this.event.id)
            .then(response => {
                // console.log(response.data.others)

                response.data.others.forEach(element => {
                    let object = {}
                    object.file = element.file
                    object.eventid = element.eventid
                    object.id = element.id
                    object.desc = element.desc
                    let extension = element.file.split('.').pop()
                    if(this.image_types.includes(extension)) {                           
                        this.slides.push(object)
                    }
                    else {
                        this.audio_video.push(object)
                    }
                });


                //this.slides = response.data.others
                if(this.slides.length > 0) {
                    this.getImages(0)
                }
            })
        },
        getImages: function (i) {
            let formData = new FormData()
            formData.append('path', this.slides[i].file)
            getFile ('filesgetfile', formData)
            .then(response => {
                // console.log("Is file!")
                this.slides[i].url = response

                if (i < this.slides.length -1) {
                    this.getImages(i + 1)         
                } 
                else {
                    let globalThis = this
                    setTimeout(function(){ globalThis.showSlides = true }, 100);
                    if (this.audio_video.length > 0) {
                        this.linkMediaWithImage()
                    }
                }
            })     
        },
        onSwiper(swiper) {
        //   console.log(swiper);
        },
        onSlideChange() {
            // console.log('slide change');
            // console.log(this.$refs.mySwiper.$swiper.realIndex)
            this.currentSlideIndex = this.$refs.mySwiper.$swiper.realIndex
            // this.checkSlide()
        },
        navigateSlides: function (direction) {
            if(direction == 'next'){
                this.$refs.mySwiper.$swiper.slideNext()
            }
            else {
                this.$refs.mySwiper.$swiper.slidePrev()
            }
        },
        goBack() {
            this.$store.dispatch('ui/change_current_interface', 4)    
        },
        linkMediaWithImage () {
            this.audio_video.forEach(audioVideo => {
                let audioVideoName = audioVideo.file.substring(0, audioVideo.file.indexOf("."))
                this.slides.forEach(slide => {
                    let imageName = slide.file.substring(0, slide.file.indexOf("."));
                    if(audioVideoName == imageName) {
                        // console.log(audioVideo.file)
                        slide.multimedia_path = audioVideo.file
                        slide.multimedia_mediaid = audioVideo.mediaid
                        let extension = audioVideo.file.split(".").pop().toUpperCase()
                        if(extension == 'WAV' || extension == 'MP3'){
                            slide.multimedia_type = "audio"
                        }
                        else if(extension == 'MP4') {
                            slide.multimedia_type = "video"
                        }
                    }
                    
                })
            })
            this.getMultimedia(0)
        },
        getMultimedia: function (i) {
            if(this.slides[i].multimedia_path) {
                let formData = new FormData()
                formData.append('path', this.slides[i].multimedia_path)
                getFile ('filesgetfile', formData)
                .then(response => {
                    // console.log("Is multimedia!")
                    this.slides[i].multimedia_url = response

                    if (i < this.slides.length -1) {
                        this.getMultimedia(i + 1)         
                    } 
                })
            }
            else {
                if (i < this.slides.length -1) {
                    this.getMultimedia(i + 1)         
                }
            }

        },
        playVideo: function (sourceurl) {
            this.isPlayingVideo = true

            var video = document.getElementById('myVideo')
            var source = document.createElement('source')

            source.setAttribute('src', sourceurl)
            source.setAttribute('type', 'video/mp4')

            video.appendChild(source)
            video.play()        
        },
        stopvid: function() {
            var video = document.getElementById('myVideo')
            video.pause()
            video.currentTime = 0
            this.isPlayingVideo = false
        },
    }
}   
</script>

<style lang="scss" scoped>
    .slide-container {
        width: 100vw;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        // height: 600px;
        position: absolute;
        height: 100%;
    }
    .map-detail-container {
        width: 100%;
        height: 200px;
    }
    .description-detail-container {
        padding: 20px;
    }

    .esdeveniments-title {
        // padding: 10px;
        background-color: #564a40cc;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffebde;
        height: 44px;
        min-height: 44px;
        max-height: 44px;
    }
    .mh-50 {
        max-height: 100vh;
    }
    .oh{
        overflow: hidden;
    }
    .width-100 {
        width: 100%;
    }
    .arrows-overlay {
        position: absolute;
        z-index: 20;
        display: flex;
        flex-direction: column;
        align-items: center;
        pointer-events: none;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        //padding: 5px 10px;
    }
    .arrow-background-up {
        width: 100%;
        // padding: 8px;
        margin-top: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0,0,0,0.3);
    }
    .arrow-up {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    .arrow-background-down {
        width: 100%;
        // padding: 8px;
        // margin-bottom: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0,0,0,0.3);
    }
    .arrow-down {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    .slide-background {
        background-size: cover !important;
        background-repeat: no-repeat !important;
        filter: blur(0.5rem);
    }
    .slider-background {
        position: absolute; top: 0; height: 100%; width: 100%;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        filter: blur(0.5rem);
    }
    .slider-foreground {
        position: absolute; 
        top: 0; 
        height: 100%; 
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .slide-media {
        height: 40%;
        background-size: contain !important;
        max-width: 88%;
        min-width: 88%;
        background-repeat: no-repeat !important;
        background-position: center !important;
        margin-top: 50px;
        margin-bottom: 23px;
    }
    .slide-desc {
        flex: 1;
        //background-color: rgba(0,0,0,0.5);
        padding: 15px;
        overflow-y: scroll;
        width: 100%;
        
        font-size: 0.85rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
        max-width: 88%;
        
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;

        pointer-events: none;

        // Option 1
        background-color: rgba(0,0,0,0.5);
        color: white;
        border-radius: 6px;

        // Option 2
        // background-image: url('~@/assets/images/scroll2.png');
        // font-family: $nav-village-font;
        // font-weight: bold;
        // text-align: center;
        // color: #5b3f14;
        // font-size: 0.9rem;
    }
    .event-container {
        // Option 1
        background-color: rgba(64, 58, 49, 0.9);
    }
    .video-image {
        background-image: url('~@/assets/icons/icon_video_white.png');
        background-size: 25% !important;
        opacity: 0.75;
    }
    .video-icon {
        background-image: url('~@/assets/icons/icon_video_white.png');
        background-size: 25% !important;
        opacity: 0.75;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .audio-image {
        background-image: url('~@/assets/icons/icon_audio_white.png');
        background-size: 25% !important;
        opacity: 0.5;
    }
    .audio-icon {
        background-image: url('~@/assets/icons/icon_audio_white.png');
        background-size: 25% !important;
        opacity: 0.75;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .video-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        // pointer-events: none;
    }
    .icon-close {
        background-image: url('~@/assets/icons/icon_close.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 20px;
        top: 20px;
    }
</style>