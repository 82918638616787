import Vue from 'vue'
import Vuex from 'vuex'
import ui from './modules/ui.js'
import village from './modules/village.js'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    ui,
    village
  },
  strict: debug,
  state: {
  },
  mutations: {
  },
  actions: {
  },
})
