<template>
  <div>
    <div class="full-container">
      <div id="loading-container-map" class="loading-gif-container">
        <div style="font-size: 0.9rem; color: #555; margin-bottom: 10px;">Carregant...</div>
        <img class="loading-gif" src="@/assets/icons/loading.gif"  alt="Carregant">
      </div>
      <div id="map" class="map-container" ref="mymap"></div>
      <!-- <div class="recenter recenter-village" @click="centerView"></div> -->
      <div class="overlay-container" v-bind:class="{ 'hide-overlay': !show_overlay }">
        <div class="overlay-header" @click="toggleOverlay">
          <!-- <div class="arrow-header-container"></div> -->
          <img v-if="show_overlay" class="filter-arrow" src="@/assets/icons/icon_arrow_down_2.svg" />
          <img v-else class="filter-arrow" src="@/assets/icons/icon_arrow_up_2.svg" />
          <!-- <img v-else class="filter-arrow" src="@/assets/icons/espai/icon0.svg" /> -->
        </div>
        <div v-if="selected_location" class="location-container">
          <Location v-if="selected_location" :location="selected_location"></Location>
        </div>
        <div v-else-if="selected_type" class="location-container">
          <TypeGallery :locations="type_locations"></TypeGallery>
        </div>
        <div v-else class="filters-container" v-bind:class="{ 'icon-filter-hide': !show_overlay }">
          <!-- <div>Filtres</div> -->
          <div class="filters-column" style="width: 25%;">
            <div class="filters-row" style="height: 60%;">
              <label for="checkbox-0" class="checkbox-container" v-bind:class="{ 'type-selected': filter_check_all }">
                <input type="checkbox" id="checkbox-0" v-model="filter_check_all">
                <img class="icon-filter" src="@/assets/icons/map/icon-all.svg" />
              </label>
            </div>
            <div class="filters-row" style="height: 30%;">
              <label for="checkbox-7" class="checkbox-container" v-bind:class="{ 'type-selected': filter_especific }" @click="force_just_one_filter(0)">
                <input type="checkbox" id="checkbox-7" v-model="filter_especific">
                <img class="icon-filter" src="@/assets/icons/map/icon-specific.svg" />
              </label>
            </div>            
          </div>
          <div class="filters-column" style="flex: 1;">
            <div class="filters-row">
              <label for="checkbox-5" class="checkbox-container" v-bind:class="{ 'type-selected': filter_dormir }" @click="force_just_one_filter(5)">
                <input type="checkbox" id="checkbox-5" v-model="filter_dormir">
                <img class="icon-filter-bigger" src="@/assets/icons/map/icon-sleep.svg" />
              </label>
              <label for="checkbox-2" class="checkbox-container" v-bind:class="{ 'type-selected': filter_menjar }" @click="force_just_one_filter(4)">
                <input type="checkbox" id="checkbox-2" v-model="filter_menjar">
                <img class="icon-filter-bigger-eat" src="@/assets/icons/map/icon-eat.svg" />
              </label>
            </div>
            <div class="filters-row">
              <label for="checkbox-4" class="checkbox-container" v-bind:class="{ 'type-selected': filter_comer }" @click="force_just_one_filter(6)">
                <input type="checkbox" id="checkbox-4" v-model="filter_comer">
                <img class="icon-filter" src="@/assets/icons/map/icon-commerce.svg" />
              </label>
              <label for="checkbox-9" class="checkbox-container" v-bind:class="{ 'type-selected': filter_espais }" @click="force_just_one_filter(3)">
                <input type="checkbox" id="checkbox-9" v-model="filter_espais">
                <img class="icon-filter" src="@/assets/icons/map/icon-space.svg" />
              </label>          
            </div>
            <div class="filters-row">
              <label for="checkbox-6" class="checkbox-container" v-bind:class="{ 'type-selected': filter_entitats }" @click="force_just_one_filter(2)">
                <input type="checkbox" id="checkbox-6" v-model="filter_entitats" >
                <img class="icon-filter" src="@/assets/icons/map/icon-entity.svg" />
              </label>
              <label for="checkbox-1" class="checkbox-container" v-bind:class="{ 'type-selected': filter_salut }" @click="force_just_one_filter(1)">
                <input type="checkbox" id="checkbox-1" v-model="filter_salut">
                <img class="icon-filter-bigger" src="@/assets/icons/map/icon-health.svg" />
              </label>                                
            </div>
          </div>
          <div class="filters-column" style="width: 25%;">
            <div class="filters-row" style="height: 60%;">
              <label for="checkbox-8" class="checkbox-container" v-bind:class="{ 'type-selected': filter_PIT }" @click="force_just_one_filter(8)">
                <input type="checkbox" id="checkbox-8" v-model="filter_PIT">
                <img class="icon-filter-bigger-eiffel" src="@/assets/icons/map/icon-pit.svg" />
              </label>
            </div>
            <div class="filters-row" style="height: 30%;">
              <label for="checkbox-3" class="checkbox-container" v-bind:class="{ 'type-selected': filter_aparcament }" @click="force_just_one_filter(7)">
                <input type="checkbox" id="checkbox-3" v-model="filter_aparcament">
                <img class="icon-filter-bigger" src="@/assets/icons/map/icon-parking.svg" />
              </label>
            </div>            
          </div>          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Leaflet
import "leaflet/dist/leaflet.css"
import L from "leaflet"
import "leaflet-fullscreen/dist/leaflet.fullscreen.css"
import "leaflet-fullscreen/dist/Leaflet.fullscreen"
// Vuex
import { mapGetters } from "vuex";
// Services
import { getData } from '@/services/api'
import { opt_el_pais, el_pais} from '@/services/helper'
// Components
import Location from '@/components/village/map/Location.vue'
import TypeGallery from '@/components/village/map/TypeGallery.vue'

export default {
  name: 'VillageMap',
  components: {
    Location,
    TypeGallery
  },
  data () {
    return {
      map: null,
      basemap1: null,
      basemap2: null,
      basemap3: null,
      basemap4: null,
      currentBasemap: null,
      previousZoom: null,
      no_center: false,
      locations: [],
      selected_location: null,
      selected_type: null,
      markers: [],
      firstLoad: true,
      show_overlay: false,
      filter: ['0', '1', '2', '3', '4', '5', '6', '7', '8'],
      filter_check_all: true,
      is_reacting: false,
      filter_checks: {
        salut: false,
        menjar: false,
        aparcament: false,
        comer: false,
        dormir: false,
        entitats: false,
        especific: false,
        PIT: false,
        espais: false
      },
      icon_salut: null,
      icon_menjar: null,
      icon_aparcament: null,
      icon_comer: null,
      icon_dormir: null,
      icon_entitats: null,
      icon_especific: null,
      icon_PIT: null,
      icon_espais: null,
      icon_user: null,
      user: null,
      firstLocation: true,

      filter_salut: false,
      filter_menjar: false,
      filter_aparcament: false,
      filter_comer: false,
      filter_dormir: false,
      filter_entitats: false,
      filter_especific: false,
      filter_PIT: false,
      filter_espais: false,
      enter_just_once: true,
      type_locations: [],

      userLong: null,
      userLat: null,
      watchLocationId: null,
    }
  },
  computed: { 
    ...mapGetters({
      village: 'village/village',
      locate_trigger: 'village/locate_trigger',
    }) 
  },
  watch: {
    filter: function () {
      this.setMarkers()
      //this.activateTypeGallery()
    },
    filter_check_all: function () {
      if(this.filter_check_all)
        this.setFilters(true)
    },
    // filter_checks: {
    //   handler(){
    //     this.setFilters(false)
    //   },
    //   deep: true
    // },
    locate_trigger: function () {
      if(this.locate_trigger) {
        //console.log(this.locate_trigger)
        if(!this.user) {
          this.no_center = true
          this.firstLocation = true
          this.locateUser()
        }
        else {
          this.stopLocate()
        }
      }
      
    }
  },
  mounted () {
    this.setBaseMap()
    this.getLocations()
    this.loadIcons()
    this.$store.dispatch("ui/changeLoadingLoc", false)
  },
  methods: {
    force_all_filters: function () {

    },
    force_just_one_filter: function (type_id) {
      this.filter_salut= false
      this.filter_menjar= false
      this.filter_aparcament= false
      this.filter_comer= false
      this.filter_dormir= false
      this.filter_entitats= false
      this.filter_especific= false
      this.filter_PIT= false
      this.filter_espais= false
   
      switch (type_id) {
        case 0:
          this.filter_especific = true
          break;
        case 1:
          this.filter_salut = true
          break;
        case 2:
          this.filter_entitats = true
          break;
        case 3:
          this.filter_espais = true
          break;
        case 4:
          this.filter_menjar = true
          break;
        case 5:
          this.filter_dormir = true
          break;
        case 6:
          this.filter_comer = true
          break;
        case 7:
          this.filter_aparcament = true
          break;
        case 8:
          this.filter_PIT = true
          break;
      }

      this.filter = ['' + type_id]
      this.filter_check_all = false
      this.activateTypeGallery()
      //this.setFilters(false)
    },
    setBaseMap: function () {
      this.map = L.map("map", {zoomControl: true, minZoom: 3, maxZoom: 20, enableHighAccuracy: true}).setView([this.village.lat, this.village.long], 16);
      this.basemap1 = L.tileLayer.wms('https://geoserveis.icgc.cat/icc_mapesmultibase/noutm/wms/service?', {
      //this.basemap1 = L.tileLayer.wms('https://geoserveis.icgc.cat/servei/catalunya/orto-territorial/wms?', {
              layers: 'orto',
              // layers: 'ortofoto_color_serie_anual',
              // format: 'image/png',
              // EPSG: 4326,
              // minZoom: 12,
              maxZoom: 20,
              transparent: true,
              continuousWorld: true,
              attribution: 'ICGC | Geo-Memorial'
            }),
      this.basemap2 = L.tileLayer.wms('https://geoserveis.icgc.cat/map/bases/service?', {
      //this.basemap2 = L.tileLayer.wms('https://geoserveis.icgc.cat/icc_bt25m/wms/service?', {
              // layers: '02_ALTI_PA,03_POBL_PA,04_POBL_PA,05_VEGE_PA,06_VEGE_PA,07_POBL_PA,08_VEGE_PA,09_VEGE_LN,10_VEGE_LN,11_HIDR_PA,12_HIDR_PA,13_ALTI_LN,14_ALTI_LN,15_ALTI_LN,16_ALTI_LN,18_POBL_PA,19_HIDR_PA,20_HIDR_LN,21_HIDR_LN,22_HIDR_LN,23_HIDR_LN,24_POBL_LN,25_POBL_LN,26_ALTI_LN,27_ALTI_LN,28_POBL_LN,29_POBL_LN,30_POBL_PN,31_VIES_LN,32_VIES_LN,33_VIES_LN,34_VIES_LN,35_VIES_LN,36_VIES_LN,37_VIES_LN,38_POBL_LN,39_HIDR_LN,40_VIES_LN,41_VIES_LN,42_VIES_LN,43_POBL_LN,44_POBL_LN,45_TOPO_TX,46_PREF_PNTX,47_ALTI_PNTX,48_ALTI_TX',
              layers: 'topo',
              format: 'image/png',
              // EPSG: 4326,
              minZoom:12,
              maxZoom: 17,                
              transparent: true,
              continuousWorld: true,
              attribution: 'ICGC | Geo-Memorial'
            });

      this.basemap3 = L.tileLayer.wms('https://tilemaps.icgc.cat/mapfactory/service?', {
              layers: 'osm_suau',
              format: 'image/png',
              EPSG: 4326,
              // minZoom: 7,
              // maxZoom: 11,            
              transparent: true,
              continuousWorld: true,
              attribution: 'ICGC | Geo-Memorial'
            }),

     this.basemap4 = L.tileLayer.wms('https://tilemaps.icgc.cat/mapfactory/service?', {
              layers: 'mon_cat',
              format: 'image/png',
              EPSG: 4326,
              // minZoom: 3,
              // maxZoom: 6,           
              transparent: true,
              continuousWorld: true,
              attribution: 'ICGC | Geo-Memorial'
            }),
     
      this.currentBasemap = this.basemap1
      this.currentBasemap.addTo(this.map);


      const layerMapCountry = L.geoJson(el_pais, opt_el_pais)
      layerMapCountry.addTo(this.map);
      // const layerMapInit = layerMapCountry
      
      L.control.scale({imperial: false}).addTo(this.map)
      this.map.addControl(new window.L.Control.Fullscreen())
      this.zoomLevelWidget()
      this.centerVillageWidget()
    },

    centerVillageWidget: function () {
      L.Control.MyControl = L.Control.extend({
        onAdd: function(map) {
          var el = L.DomUtil.create('div');

          el.innerHTML = '';
          el.setAttribute("id", "center-village");
          el.setAttribute("style", "background-color: rgba(255,255,255,1); width: 34px; height: 34px; border: 2px solid rgba(0,0,0,0.25); border-radius: 4px; display: flex; align-items: center; justify-content: center; padding: 5px;");

          return el;
        },
        onRemove: function(map) {
          // Nothing to do here
        }
      });
      L.control.myControl = function(opts) {
        return new L.Control.MyControl(opts);
      }
      L.control.myControl({
        position: 'topright'
      }).addTo(this.map);

      const centerEl = document.getElementById("center-village");
      centerEl.addEventListener("click", functionCenter);
      let globalThis = this
      function functionCenter () {
        globalThis.centerView()
      }

      var elImg = document.createElement("img")
      elImg.setAttribute("src", "/img/icon0.0b1b2ad0.svg")
      centerEl.appendChild(elImg)
    },
    zoomLevelWidget: function () {
      L.Control.MyControl = L.Control.extend({
        onAdd: function(map) {
          var el = L.DomUtil.create('div');

          el.innerHTML = 'Myasdasd';
          el.setAttribute("id", "zoom-level");
          el.setAttribute("style", "background-color: rgba(255,255,255,1); width: 34px; height: 34px; border: 2px solid rgba(0,0,0,0.25); border-radius: 25px; display: flex; align-items: center; justify-content: center;");

          return el;
        },
        onRemove: function(map) {
          // Nothing to do here
        }
      });
      L.control.myControl = function(opts) {
        return new L.Control.MyControl(opts);
      }
      L.control.myControl({
        position: 'topleft'
      }).addTo(this.map);

      document.getElementById("zoom-level").innerHTML = this.map.getZoom()
      let globalThis = this
      this.map.on("zoomend", function (e) { document.getElementById("zoom-level").innerHTML = globalThis.map.getZoom(); globalThis.setBaseMapsByZoomLevel(globalThis.map.getZoom());});
    },

    setBaseMapsByZoomLevel: function (zoomLevel) {
      // BASE MAP
      if(zoomLevel >= 18) {
        if (this.currentBasemap != this.basemap1) {
          this.map.removeLayer(this.currentBasemap)
          this.currentBasemap = this.basemap1
          this.currentBasemap.addTo(this.map)
        }
      }
      else if (zoomLevel <= 17 && zoomLevel >= 12) {
        if (this.currentBasemap != this.basemap2) {
          this.map.removeLayer(this.currentBasemap)
          this.currentBasemap = this.basemap2
          this.currentBasemap.addTo(this.map)
        }
      }
      else if (zoomLevel <= 11 && zoomLevel >= 7) {
        if (this.currentBasemap != this.basemap3) {
          this.map.removeLayer(this.currentBasemap)
          this.currentBasemap = this.basemap3
          this.currentBasemap.addTo(this.map)
        }
      }
      else if (zoomLevel <= 6 && zoomLevel >= 3) {
        if (this.currentBasemap != this.basemap4) {
          this.map.removeLayer(this.currentBasemap)
          this.currentBasemap = this.basemap4
          this.currentBasemap.addTo(this.map)
        }
      }

      // CENTER VIEW
      if (this.no_center) {
        this.previousZoom = zoomLevel
        this.no_center = false
      }
      else {
        if (zoomLevel >= 9 && (this.previousZoom < 9 || this.previousZoom == null)) {
          this.map.panTo(new L.LatLng(this.village.lat, this.village.long))
          this.previousZoom = 9
        }
        if (zoomLevel <= 8 && (this.previousZoom > 8 || this.previousZoom == null)) {
          this.map.panTo(new L.LatLng(41.593300, 1.837400))
          this.previousZoom = 8
        }
      }
      
    },

    getLocations: function () {
      getData('locations/' + this.village.id)
      .then (response => {
        //console.log(response)
        this.locations = response.data.locations
        this.setMarkers()
        //this.$store.dispatch('village/change_locations', response.data.locations)
      })
    },
    loadIcons: function () {
      let image_salut = require('@/assets/icons/map/marker-health.svg')
      let image_menjar = require('@/assets/icons/map/marker-eat.svg')
      let image_aparcament = require('@/assets/icons/map/marker-parking.svg')
      let image_comer = require('@/assets/icons/map/marker-commerce.svg')
      let image_dormir = require('@/assets/icons/map/marker-sleep.svg')
      let image_entitats = require('@/assets/icons/map/marker-entity.svg')
      let image_especific = require('@/assets/icons/map/marker-specific.svg')
      let image_PIT = require('@/assets/icons/map/marker-pit.svg')
      let image_espais = require('@/assets/icons/map/marker-space.svg')
      let image_user = require('@/assets/icons/espai/icon1.svg')
      this.icon_salut = L.icon({
        iconUrl: image_salut,
        iconSize:     [60, 60], // size of the icon
        iconAnchor:   [30, 60], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
      this.icon_menjar = L.icon({
        iconUrl: image_menjar,
        iconSize:     [60, 60], // size of the icon
        iconAnchor:   [30, 60], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
      this.icon_aparcament = L.icon({
        iconUrl: image_aparcament,
        iconSize:     [60, 60], // size of the icon
        iconAnchor:   [30, 60], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
      this.icon_comer = L.icon({
        iconUrl: image_comer,
        iconSize:     [60, 60], // size of the icon
        iconAnchor:   [30, 60], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
      this.icon_dormir = L.icon({
        iconUrl: image_dormir,
        iconSize:     [60, 60], // size of the icon
        iconAnchor:   [30, 60], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
      this.icon_entitats = L.icon({
        iconUrl: image_entitats,
        iconSize:     [60, 60], // size of the icon
        iconAnchor:   [30, 60], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
      this.icon_especific = L.icon({
        iconUrl: image_especific,
        iconSize:     [60, 60], // size of the icon
        iconAnchor:   [30, 60], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
      this.icon_PIT = L.icon({
        iconUrl: image_PIT,
        iconSize:     [60, 60], // size of the icon
        iconAnchor:   [30, 60], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
      this.icon_espais = L.icon({
        iconUrl: image_espais,
        iconSize:     [60, 60], // size of the icon
        iconAnchor:   [30, 60], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
      this.icon_user = L.icon({
        iconUrl: image_user,
        iconSize:     [40, 40], // size of the icon
        iconAnchor:   [20, 20], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
    },
    setMarkers: function () {
      this.markers.forEach(marker => {
        this.map.removeLayer(marker) 
      })
      this.markers = []
      this.locations.forEach(location => {
        let typeArray = this.formatTypeArray(location.type)
        let currentIcon = null
        let isInFilter = false
        let filterType = null
        typeArray.forEach(type => {
          if (this.filter.includes(type)) {
            isInFilter = true
            filterType = type
          }
        })

        if(isInFilter) {
          if (filterType == "0") {
            currentIcon = this.icon_especific
          }
          else if (filterType == "1") {
            currentIcon = this.icon_salut
          }
          else if (filterType == "2") {
            currentIcon = this.icon_entitats
          }
          else if (filterType == "3") {
            currentIcon = this.icon_espais
          }
          else if (filterType == "4") {
            currentIcon = this.icon_menjar
          }
          else if (filterType == "5") {
            currentIcon = this.icon_dormir
          }
          else if (filterType == "6") {
            currentIcon = this.icon_comer
          }
          else if (filterType == "7") {
            currentIcon = this.icon_aparcament
          }
          else if (filterType == "8") {
            currentIcon = this.icon_PIT
          }
          this.markers.push(L.marker([location.lat, location.long], {icon: currentIcon}))
          this.markers[this.markers.length - 1].addTo(this.map).on("click", () => {
            this.selectLocation(location)
          })
        }
      })
      this.centerView()
      if (this.firstLoad) {
        this.firstLoad = false
        this.centerView()
      }
    },
    centerView: function () {
      let group = new L.featureGroup(this.markers)    
      //this.show_overlay = false
      let oldBounds = group.getBounds()
      let _northEast = oldBounds._northEast
      let _southWest = oldBounds._southWest
      _southWest.lat -= _northEast.lat - _southWest.lat
      oldBounds._southWest = _southWest

      this.map.fitBounds(oldBounds)

      let globalThis = this
      setTimeout(function(){ globalThis.hideLoader() }, 250);
      
    },
    formatTypeArray(typestring) {
        let typeArray = typestring.split(",");
        for(let i = 0; i < typeArray.length; i++) {
            if(i == 0) {
                typeArray[i] = typeArray[i].substring(1)
            }
            if(i == typeArray.length -1) {
                typeArray[i] = typeArray[i].slice(0, -1)
            }
        }
        return typeArray
    },
    setFilters: function(is_all) {
        if(is_all) { 
          if(this.filter_check_all){
            // Object.entries(this.filter_checks).forEach(([key, value]) => {
            //   this.filter_checks[key] = false
            // });
            this.is_reacting = true
            this.filter_checks = {
              salut: false,
              menjar: false,
              aparcament: false,
              comer: false,
              dormir: false,
              entitats: false,
              especific: false,
              PIT: false,
              espais: false
            }
            this.filter_salut= false
            this.filter_menjar= false
            this.filter_aparcament= false
            this.filter_comer= false
            this.filter_dormir= false
            this.filter_entitats= false
            this.filter_especific= false
            this.filter_PIT= false
            this.filter_espais= false
            this.filter = ['0', '1', '2', '3', '4', '5', '6', '7', '8']
          }
          else {
            if (this.is_reacting) {
              this.is_reacting = false
            }
            else {
              this.filter = []
            }
          }
        }
        else {
          if (this.is_reacting) {
            this.is_reacting = false
          }
          else {
            if (this.filter_check_all) {
              this.is_reacting = true
              this.filter_check_all = false
            }

            this.filter = []
            Object.entries(this.filter_checks).forEach(([key, value]) => {
              if (value == true){
                if (key == 'especific') this.filter.push('0')
                else if (key == 'salut') this.filter.push('1')
                else if (key == 'entitats') this.filter.push('2')
                else if (key == 'espais') this.filter.push('3')
                else if (key == 'menjar') this.filter.push('4')
                else if (key == 'dormir') this.filter.push('5')
                else if (key == 'comer') this.filter.push('6')
                else if (key == 'aparcament') this.filter.push('7')
                else if (key == 'PIT') this.filter.push('8')
              }
            });
          }
        } 
    },
    toggleOverlay: function () {
      if(this.show_overlay){
        let globalThis = this
        setTimeout(function(){ 
          globalThis.selected_location = null
          globalThis.selected_type = null
        }, 250);
      }
      this.show_overlay = !this.show_overlay
    },
    selectLocation: function (location) {
      this.selected_location = null
      //console.log(location)
      this.selected_location = location
      this.show_overlay = true
    },

    // LOCATE USER METHODS
    locateUser: function () {
      this.show_overlay = false
      if (!this.user) {
        this.watchGeoLocation()
        //this.map.locate({watch: true, maxZoom: 16}).on('locationfound', this.onLocationFound)
        this.$store.dispatch("ui/changeLoadingLoc", true)
      }
      // else {
      //   this.centerViewWithUser()
      // }
    },
    watchGeoLocation: function () {
      if (navigator.geolocation) {
          //if location services are turned on, continue and call the getUserCoordinates function below
          //navigator.geolocation.getCurrentPosition(this.getUserCoordinates, function error(msg) {alert('Please enable your GPS position feature.');}, {maximumAge:10000, timeout:5000, enableHighAccuracy: true})
          //navigator.geolocation.getCurrentPosition(this.onLocationFound, function error(msg) {alert('Please enable your GPS position feature.');}, {maximumAge:0, timeout:10000, enableHighAccuracy: true})
          this.watchLocationId = navigator.geolocation.watchPosition(this.onLocationFound, function error(msg) {alert('Activa la teva funció de posició GPS.');}, {maximumAge:0, timeout:10000, enableHighAccuracy: true})
      }
      // else {
      //     alert('Activa la teva funció de posició GPS.');
      // }
    },
    onLocationFound(e){
      this.$store.dispatch("ui/changeLoadingLoc", false)
      if (!this.user) {
        console.log(e.coords.latitude)
        console.log(e.coords.longitude)      
        console.log(e)
          this.user = L.marker([e.coords.latitude, e.coords.longitude], { icon: this.icon_user }).addTo(this.map);
      } else {
          this.user.setLatLng([e.coords.latitude, e.coords.longitude]);
      }
      if (this.firstLocation) {
        this.firstLocation = false
        this.centerViewWithUser()
      }
    },
    centerViewWithUser: function () {
      let groupArray = []
      this.markers.forEach(marker => {
        groupArray.push(marker)
      })
      groupArray.push(this.user)
      let group = new L.featureGroup(groupArray)
      this.map.fitBounds(group.getBounds())
    },
    stopLocate: function() {
      if (this.user) {
        //this.map.stopLocate()
        navigator.geolocation.clearWatch(this.watchLocationId);
        this.map.removeLayer(this.user)
        this.user = null
        this.$store.dispatch('ui/change_current_interface', 99)
        this.firstLocation = true
      }
    },

    // LOCATION TYPE GALLERY
    activateTypeGallery: function() {
      if(this.enter_just_once) {
        this.enter_just_once = false
        if (this.filter.length > 0 && this.filter.length < 9) {
          this.type_locations = []
          this.locations.forEach(location => {
            let locArray = location.type.slice(1, -1)
            locArray = locArray.split(',')
            if (locArray.includes(this.filter[0])) {
              this.type_locations.push(location)
            }
            
          })
          this.selected_type = this.filter[0]
          //this.show_overlay = true
        }
      }
      else {
        this.enter_just_once = true
      }  
    },


    // LOADER
    hideLoader: function () {
      document.getElementById("loading-container-map").classList.add("loading-gif-container-hide")
    },
    showLoader: function () {
      document.getElementById("loading-container-map").classList.remove("loading-gif-container-hide")
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>
.overlay-container {
  position: absolute;
  width: 100%;
  background-color: $app-color-3;
  z-index: 10000;
  bottom: 0;
  overflow: hidden;
  height: 42%;

  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}
.hide-overlay {
  height: 40px;
}
.icon-filter {
  max-height: 85%;
  max-width: 85%;
  height: auto;
  height: 54px;
  padding: 0px;
  opacity: 1;

  &-bigger {
    height: 59px;
    max-height: 85%;
    max-width: 85%;
    &-eat {
      height: 54px;
      max-height: 85%;
      max-width: 85%;
    }

    &-eiffel {
      height: 86px;
      max-height: 85%;
      max-width: 85%;
    }
  }
}
.icon-filter-hide {
  opacity: 0;
}
.filters-container {
  padding: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 30px);
  margin-top: 5px;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}
.filters-column {
  display: flex;
  flex-direction: column;
}
.filters-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 30%;
}
.overlay-header {
  width: 100%;
  height: 40px;
  background-color: $app-color-2;
  opacity: 1;
  /*border-top: 1px solid $app-color-1;*/
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 3px 2px rgba(0,0,0,0.2);
  border-top: 1px solid #958c85
}
.arrow-header-container {
  background-color: pink;
  height: 30px;
  width: 30px;
  position: absolute;
  top: -15px;
}
.filter-arrow {
  height: 50%;
  width: auto;
  opacity: 0.25;
}
.location-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 30px);
  pointer-events: none;
}
/* CHECKBOXES */
input[type="checkbox"][id^="checkbox-"] {
  display: none;
}
label {
  background-color: #e6ded8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: 1px solid #b2a79e;
  margin: 3px;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 1px rgb(0 0 0 / 10%);

  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}
.type-selected {
  /* border: 1px solid #f5a06d; */
  box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 30%);
  background-color: #f3ece7;
}
/* label::before {
  background-color: #f3ece7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: 1px solid #b2a79e;
  margin: 3px;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 20%);
}
:checked+label {
  background-color: green;
}
:checked+label::before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
} */

/* LOCATION */
.recenter {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 25px;
  height: 25px;
  z-index: 9999;
  opacity: 0.4;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.recenter-user {
  right: 20px;
  background-image: url('~@/assets/icons/espai/icon1.svg');
}
.recenter-village {
  right: 70px;
  background-image: url('~@/assets/icons/espai/icon0.svg');
}
.zoom-level-custom {
  width: 34px;
  height: 34px;
  background-color: white;
  position: relative;
  border: 2px solid rgba(0,0,0,0.14);
  border-radius: 4px;
}

.loading-gif-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-transition: 0.3s ease-out;
    -moz-transition: 0.3s ease-out;
    -o-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
    opacity: 1;
    z-index: 10000;
    background-color: $app-color-3;
  }
  .loading-gif-container-hide {
    opacity: 0;
    pointer-events: none;
  }
  .loading-gif {
    width: 18%;
    height: auto;
  }
</style>