<template>
  <!-- <div class="location-container-inside" @click="closeLocation" @touchstart="fadeOverlay" @touchend="checkSlide"> -->
  <div class="location-container-inside">
    <div id="loading-container" class="loading-gif-container">
      <div style="font-size: 0.9rem; color: #555; margin-bottom: 10px;">Carregant...</div>
      <img class="loading-gif" src="@/assets/icons/loading.gif"  alt="Carregant">
    </div>
    <div class="arrows-overlay">
      <img v-if="currentSlideIndex > 0" class="filter-arrow" src="@/assets/icons/icon_arrow_left_2.svg" style="height: 30px; pointer-events: all;" @click="navigateSlides('prev')" />
      <div style="flex: 1;"></div>
      <img v-if="currentSlideIndex < slide.length - 1" class="filter-arrow" src="@/assets/icons/icon_arrow_right_2.svg" style="height: 30px; pointer-events: all;" @click ="navigateSlides('next')" />
    </div>
    <div class="location-overlay" v-bind:class="{ 'location-overlay-hidden': !show_text }">
      <div class="location-main-title">{{ location.name }}</div>
      <div class="text-line" style="margin-top: 3px;"><span class="location-text-title">Horari:</span> <span class="location-text">{{ location.opentime }}</span></div>
      <div class="text-line"><span class="location-text-title">Telèfon:</span> <span class="location-text">{{ location.phone }}</span> </div> 
      <div class="text-line"><span class="location-text-title">Web:</span> <span class="location-text">{{ location.website }}</span> </div>
      <div class="text-line"><span class="location-text-title">Adreça:</span> <span class="location-text">{{ location.address }}</span> </div>
      <div class="text-line" style="margin-top: 8px; padding-top: 3px; border-top: 1px solid rgba(0,0,0,0.5); width: 100%;"><span class="location-text">{{ location.desc }}</span></div>
    </div>
    <swiper
      style="pointer-events: all; width: 100%;"
      ref="mySwiper"
      :slides-per-view="1"
      :space-between="50"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide v-for="(image, index) in slide" v-bind:key="index"
        class="slide-style"
        :style="{
            backgroundImage: 'url(' + image + ')',
        }"
      >
      <span style="font-size: 0.5rem;">.</span>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getData, postData, getFile } from '@/services/api'

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// Import Swiper Vue.js components
//import { Swiper, SwiperSlide } from 'swiper/vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default {
  name: 'Location',
  components: { 
    Swiper,
    SwiperSlide 
  },
  data () {
    return {
      currentSlideIndex: 0,
      slide: [],
      imagesPaths: [],
      show_text: false
    }
  },
  props: {
    location: Object
  },
  computed: { 
    ...mapGetters({
        village: 'village/village',
    }) 
  },
  watch: { 
    location: function() {
      this.getImagesPath()
      this.showLoader()
    }
  },
  mounted () {
    this.getImagesPath()
  },
  methods: {
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      // console.log('slide change');
      // console.log(this.$refs.mySwiper.$swiper.realIndex)
      this.currentSlideIndex = this.$refs.mySwiper.$swiper.realIndex
      this.checkSlide()
    },
    navigateSlides: function (direction) {
      if(direction == 'next'){
        this.$refs.mySwiper.$swiper.slideNext()
      }
      else {
        this.$refs.mySwiper.$swiper.slidePrev()
      }
    },

    getImagesPath: function () {
      this.slide = []
      this.imagesPaths = []
      getData('fileslocations/' + this.location.id)
      .then (response => {
        //console.log(response.data)
        if (response.data.main) {
          this.imagesPaths.push(response.data.main)
        }
        response.data.others.forEach(image => {
          this.imagesPaths.push(image)
        });
        if(this.imagesPaths.length > 0){
          this.getImages(0)
        }
      })
    },
    getImages: function (index) {
      let formData = new FormData()
      formData.append('path', this.imagesPaths[index])
      getFile ('filesgetfile', formData)
      .then(response => {
          this.slide.push(response)
          if(index == 0) {
            this.checkSlide()
            this.hideLoader()
          }
          if(index < this.imagesPaths.length -1) {
            this.getImages(index + 1)
          }
      })
    },
    closeLocation: function () {
      this.$parent.show_overlay = false
      let globalThis = this
      setTimeout(function(){ globalThis.$parent.selected_location = null }, 250);   
    },
    checkSlide: function () {
      let globalThis = this
      setTimeout(function(){    
        if(globalThis.currentSlideIndex == 0) {
          globalThis.show_text = true
        }
        else {
          globalThis.show_text = false
        }
      }, 250)
    },
    fadeOverlay: function () {
      this.show_text = false;
    },
    hideLoader: function () {
      document.getElementById("loading-container").classList.add("loading-gif-container-hide")
    },
    showLoader: function () {
      document.getElementById("loading-container").classList.remove("loading-gif-container-hide")
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>
  .location-container-inside {
    width: 100%;
    height: 100%;
    z-index: -5;
    /* background-color: orange; */
    /* margin: 5px 0px; */

    display: flex;
    flex-direction: row;
    background-color: #49413a;
  }
  .event-image-container {
    height: 90px;
    width: 90px;
    background-color: pink;
    background-size: cover;
    background-position: center;
  }
  .event-text-container {
    flex: 1;
    height: 90px;
    background-color: blue;
  }
  .location-overlay {
    position: absolute;
    z-index: 10;
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    pointer-events: all;
    left: 8px;
    right: 50px;
    top: 50px;
    bottom: 14px;
    overflow-y: scroll;
    background-color: rgba(243, 227, 214, 0.8);
    padding: 5px 10px;
    border-radius: 6px;
    opacity: 1;

    -webkit-transition: 0.2s ease-out;
    -moz-transition: 0.2s ease-out;
    -o-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
  }
  .location-overlay-hidden {
    opacity: 0;
    pointer-events: none;
  }
  .location-main-title {
    font-weight: 600;
    border-bottom: 1px solid $app-color-1;
    width: 100%;
  }
  .location-text-title {
    font-size: 0.7rem;
    font-weight: 600;
    color: #352211;
  }
  .location-text {
    font-size: 0.7rem;
  }
  .text-line {
    line-height: 0.8;
  }
  .arrows-overlay {
    position: absolute;
    z-index: 20;
    display: flex;
    flex-direction: row;
    align-items: center;
    pointer-events: none;
    left: 8px;
    right: 8px;
    top: 34px;
    bottom: 14px;
    padding: 5px 10px;
  }
  .slide-style {
    background-size: contain;
    background-repeat: no-repeat;
    background-position:center;
  }
  .loading-gif-container {
    position: absolute;
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-transition: 0.3s ease-out;
    -moz-transition: 0.3s ease-out;
    -o-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
    opacity: 1;
    z-index: 10000;
    background-color: $app-color-3;
  }
  .loading-gif-container-hide {
    opacity: 0;
    pointer-events: none;
  }
  .loading-gif {
    width: 18%;
    height: auto;
  }
</style>
