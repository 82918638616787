<template>
  <div class="event-card-container" @click="goTo()">
    <div class="event-top-row">
      <div 
          class="event-image-container"
          :style="{
              backgroundImage: 'url(' + event.image_url + ')'
          }"
      >
      </div>
      <div class="event-text-container">
        <div class="event-title">{{ this.event.title }}</div>
        <div style="flex: 1;"></div>
        <div style="display: flex;">
          <img src="@/assets/icons/espai/icon3.svg"  alt="Calendari" style="width: 12px; opacity: 0.5; margin-right: 8px;">
          <div class="event-date">{{ this.event.date_show }}</div>
        </div>   
      </div>
    </div>
    <div class="event-separator-row"></div>
    <div class="event-bottom-row">
      <p>{{this.event.desc}}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getData, postData, getFile } from '@/services/api'

export default {
  name: 'Event',
  props: {
    event: Object
  },
  methods: {
    goTo: function () {
      this.$store.dispatch('ui/change_current_event', this.event)
      this.$store.dispatch('ui/change_current_interface', 5)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>
  .event-card-container {
    width: 100%;
    height: 200px;
    background-color: #fbecd6;
    margin-bottom: 20px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);
    border: 1px solid #a68e74;
  }
  .event-top-row {
    height: 100px;
    display: flex;   
    flex-direction: row;
  }
  .event-separator-row {
    height: 8px;
    margin: 0px 20px 8px 20px;
    border-bottom: 1px solid #baa691;
  }
  .event-bottom-row {
    height: 75px;
    font-size: 0.9rem;
    text-align: justify;
    text-justify: inter-word;
    overflow: scroll;
    padding: 0px 12px 12px 12px;
    margin-bottom: 8px;
    color: #5e5344;
    line-height: 16px;
  }
  .event-image-container {
    height: 90px;
    width: 90px;
    background-color: pink;
    background-size: cover;
    background-position: center;
  }
  .event-text-container {
    flex: 1;
    height: 90px;
    margin-left: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .event-title { 
    font-size: 1.1rem;
    margin-top: 8px;
    line-height: 20px;
    font-weight: 600;
    color: #874845;
  }
  .event-date {
    font-size: 0.8rem;
    color: #5e5344;
  }
</style>
